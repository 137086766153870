/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/css/bootstrap.min.css"
import "./src/styles/css/flaticon.css"
import "./src/styles/css/remixicon.css"
import "react-tabs/style/react-tabs.css"
import "./node_modules/aos/dist/aos.css"
import "react-accessible-accordion/dist/fancy-example.css"
import "swiper/css"
import "swiper/css/bundle"

// Global Styles
import "./src/styles/css/style.css"
import "./src/styles/css/responsive.css"
import "./src/styles/css/global.css"

// Dark Theme Styles
import "./src/styles/css/dark-theme.css"

export const onInitialClientRender = () => {
  const hash = window.location.hash
  if (hash) {
    setTimeout(() => {
      const targetElement = document.querySelector(`${hash}`)
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" })
      }
    }, 400)
  }
}
